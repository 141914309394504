const WEAVR_TOKEN_KEY = "wv_token";
const WEAVR_USER_ID_KEY = "wv_user_id";

export const getToken = () => {
  return window.localStorage.getItem(WEAVR_TOKEN_KEY);
};

export const saveToken = (token) => {
  window.localStorage.setItem(WEAVR_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(WEAVR_TOKEN_KEY);
};

export const getUserId = () => {
  return window.localStorage.getItem(WEAVR_USER_ID_KEY);
};

export const saveUserId = (userId) => {
  window.localStorage.setItem(WEAVR_USER_ID_KEY, userId);
};

export const destroyUserId = () => {
  window.localStorage.removeItem(WEAVR_USER_ID_KEY);
};

export const saveAuthentication = (token, userId) => {
  saveToken(token);
  saveUserId(userId);
}

export const loggedIn = () => {
  return (getToken() && getUserId());
}

export const logout = () => {
  destroyToken();
  destroyUserId();
}

const weavrAuthenticationStorage = {
  getToken,
  saveToken,
  destroyToken,
  getUserId,
  saveUserId,
  destroyUserId,
  saveAuthentication,
  loggedIn,
  logout
};

export default weavrAuthenticationStorage;