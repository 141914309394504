<template>
  <Header />
  <p @click="goBack">
    <img src="../../assets/img/ico/back.png" />
  </p>
  <div class="page-operations">
    <CardSection />
    <div class="div-separator-64"></div>
    <CampaignList />
    <div class="div-separator-32"></div>
    <OperationList />
  </div>
</template>

<script>
  import Header from "@/components/Header.vue";
  import CardSection from "@/components/Card/CardSection.vue";
  import CampaignList from "@/components/Campaign/CampaignList.vue";
  import OperationList from "@/components/Operation/OperationList.vue";

  export default {
    methods: {
      goBack() {
          this.$router.go(-1)
      },
    },
    components: { Header, CardSection, CampaignList, OperationList },
  };
</script>

<style scoped lang="scss">
  .page-operations {
    font-family: Figtree;
    font-style: normal;
    line-height: normal;

    display: flex;
    justify-content: center;
    flex-direction: column;

    max-width: 1000px;
    margin: 0 auto;

    .dashbord-header {
      position: relative;
    }
  }

  .div-separator-64 {
    height: 64px;
  }

  .div-separator-32 {
    height: 32px;
  }
</style>