<template>
  <Header />
  <div class="dashbord">
    <div class="tabs">
      <div class="tabset">
        <!-- Tab 1 -->
        <input
          type="radio"
          name="tabset"
          id="tab1"
          aria-controls="marzen"
          checked
        />
        <label for="tab1">Ma carte</label>
        <!-- Tab 2
          <input type="radio" name="tabset" id="tab2" aria-controls="rauchbier">
          <label for="tab2">Compagnes</label>  -->
        <div class="tab-panels">
          <section id="operations-wrapper" class="tab-panel">
            <br /><br /><br />
            <div class="card-details">
              <div class="card">
                <div class="card-inner">
                  <div class="card-front">
                    <img
                      src="../../assets/img/pictos/mastercard-2-logo.svg"
                      class="master-card"
                    />

                    <img class="card-img" src="@/assets/img/carte.png" />
                    <!--<div v-if="cardType === 'VIRTUAL'">
                      <div class="unlock-layer"></div>
                      <a href="#" class="unlock-card" @click="openModal">
                        <img src="../../assets/img/pictos/unlock.png" />
                      </a>
                    </div>
                    -->

                    <div class="card-holder">{{ nameOnCard }}</div>
                    <div class="card-number">
                      *** *** *** {{ cardNumberLastFour }}
                    </div>
                    <div class="card-valid">{{ expiryMmyy }}</div>
                  </div>
                </div>
              </div>
              <div class="menu">
                <div class="menu-link" @click="toggleMenu">
                  <img src="../../assets/img/pictos/menu.png" alt="" />
                </div>
                <ul v-if="isMenuVisible">
                  <li v-if="cardState === 'ACTIVE'">
                    <a href="/admin/Operations">
                      <img src="../../assets/img/pictos/green_lock.png" />
                      <span>Ma carte active</span>
                    </a>
                    <span class="cardInfoLink">
                      Voir mes informations et mes opérations
                    </span>
                  </li>
                  <li v-if="cardType === 'VIRTUAL'">
                    <a to="#" @click="openModal">
                      <img src="../../assets/img/pictos/red_lock.png" />
                      <span>Activer ma carte physique</span>
                    </a>
                  </li>
                  <li v-if="cardState === 'BLOCKED'">
                    <a to="#" @click="openUnBlockModal">
                      <img src="../../assets/img/pictos/green_lock.png" />
                      <span>Débloquer ma carte</span>
                    </a>
                  </li>
                  <li v-if="cardType === 'PHYSICAL' && cardState === 'ACTIVE'">
                    <a to="#" @click="openBlockModal">
                      <img src="../../assets/img/pictos/red_lock.png" />
                      <span>Bloquer ma carte</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section id="rauchbier" class="tab-panel">
            <h2>Compte</h2>
          </section>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" v-if="showModal">
    <div class="modal-content">
      <span class="close" @click="closeModal">
        <img src="../../assets/img/ico/cross.png" />
      </span>
      <h3>Code d’activation</h3>

      <div class="first-step" v-if="showStep1">
        <br />
        <img src="../../assets/img/pictos/code.png" width="150" />
        <br />
        <p style="font-size: 15px; line-height: 20px">
          Pour pouvoir activer votre carte physique, <br />
          un code d’activation doit vous être <br />
          envoyé par email à l’adresse <br /><br />
          <b>{{ userEmail }}</b
          >.<br /><br />
        </p>
        <input
          type="button"
          class="btn-modal"
          value="RECEVOIR MON CODE"
          @click="sendCodeToUnlockCard"
        />
      </div>
      <div class="second-step" v-if="showStep2">
        <br />
        <img src="../../assets/img/pictos/code.png" width="150" />
        <br />
        <p style="font-size: 15px; line-height: 20px">
          Rendez-vous dans votre boîte mail !<br />
          L’email vient d’être généré, cela peut <br />
          prendre quelques minutes.<br /><br />

          Une fois reçu, renseigner votre code <br />
          ci-dessous pour activer votre carte.<br /><br />
          <input
            type="text"
            style="border: none; border-bottom: 1px solid black; width: 100%"
            v-model="verificationCode"
            placeholder="Entrez le code de vérification"
          /><br /><br /><br />
          <input
            type="button"
            class="btn-modal"
            value="VALIDER MON CODE"
            @click="confirmCodeToUnlockCard"
          />
          <br /><br />
        </p>
      </div>
      <div class="third-step" v-if="showStep3">
        <br />
        <img src="../../assets/img/pictos/unlock_success.png" />
        <br /><br />
        <p style="font-size: 15px; line-height: 20px">
          Félicititations votre carte physique vient d’être activée !
        </p>
        <input
          type="button"
          class="btn-modal"
          value="VOIR MA CARTE"
          @click="closeModal"
        />
      </div>
    </div>
  </div>

  <div class="modal" v-if="showModalToBlock">
    <div class="modal-content">
      <span class="close" @click="closeModal">
        <img src="../../assets/img/ico/cross.png" />
      </span>
      <h3>Bloquer ma carte</h3>
      <p>Êtes-vous sûr(e) de vouloir bloquer votre carte ?</p>
      <br />
      <input
        type="button"
        class="btn-modal"
        value="Bloquer ma carte"
        @click="blockCard"
      />
    </div>
  </div>

  <div class="modal" v-if="showModalToUnBlock">
    <div class="modal-content">
      <span class="close" @click="closeModal">
        <img src="../../assets/img/ico/cross.png" />
      </span>
      <h3>Débloquer ma carte</h3>
      <p>Êtes-vous sûr(e) de vouloir débloquer votre carte ?</p>
      <input
        type="button"
        class="btn-modal"
        value="débloquer ma carte"
        @click="unBlockCard"
      />
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { weavrApiService } from "../../_services/weavrApiService";
import { ceApiService } from "../../_services/ceApiService";
import { loggerService } from "../../_services/loggerService";
import Header from "@/components/Header.vue";

export default {
  data() {
    return {
      cards: [],
      nameOnCard: "",
      expiryMmyy: "",
      balance: "",
      cardNumberToDisplay: "",
      statments: [],
      isMenuVisible: true,
      isUserMenuVisible: false,
      isModalOpen: false,
      cardType: "",
      cardState: "",
      showModal: false,
      showModalToBlock: false,
      showModalToUnBlock: false,
      cardUuid: "",
      cardId: "",
      showStep1: true,
      showStep2: false,
      showStep3: false,
      userEmail: "",
      verificationCode: ref(""),
    };
  },

  async mounted() {
    loggerService.info('Dashboard component mounted');

    try {
      loggerService.debug('Fetching card details');
      const response = await ceApiService.getCurrentUserCardDetails();
      let cardNumber = response.data["hydra:member"][0].identifiant;
      this.cardUuid = response.data["hydra:member"][0].uuid;
      this.userEmail = response.data["hydra:member"][0].user.email;

      loggerService.debug('Fetching detailed card information', { cardNumber });
      const cardDetails = await weavrApiService.getCardDetails(cardNumber);
      this.nameOnCard = cardDetails.nameOnCard;
      this.cardType = cardDetails.type;
      this.cardState = cardDetails.state.state;
      this.cardId = cardDetails.id;
      this.expiryMmyy = cardDetails.expiryMmyy.replace(
        /(\d{2})(\d{2})/,
        "$1/$2"
      );
      this.balance = cardDetails.balances.actualBalance / 100;
      this.cardNumberLastFour = cardDetails.cardNumberLastFour;
      const cardNumberToDisplay = cardDetails.cardNumber.value;

      loggerService.info('Card details loaded successfully', {
        cardType: this.cardType,
        cardState: this.cardState,
        balance: this.balance
      });
    } catch (error) {
      loggerService.error('Failed to load card details', error);
      console.error("Error:", error);
    }
  },
  methods: {
    openModal() {
      loggerService.debug('Opening activation modal');
      this.showModal = true;
    },
    closeModal() {
      loggerService.debug('Closing modal and reloading page');
      location.reload();
      this.showModal = false;
    },
    closeModal2() {
      loggerService.debug('Closing modal and navigating to Operations');
      this.showModal = false;
      trouter.push("/admin/Operations");
    },

    openBlockModal() {
      loggerService.info('User opening block card modal');
      this.showModalToBlock = true;
    },

    openUnBlockModal() {
      loggerService.info('User opening unblock card modal');
      this.showModalToUnBlock = true;
    },

    toggleMenu() {
      loggerService.debug('Menu toggled', { visible: !this.isMenuVisible });
      this.isMenuVisible = !this.isMenuVisible;
    },
    toggleUserMenu() {
      loggerService.debug('User menu toggled', { visible: !this.isUserMenuVisible });
      this.isUserMenuVisible = !this.isUserMenuVisible;
    },
    async sendCodeToUnlockCard() {
      loggerService.info('User requesting activation code', { cardUuid: this.cardUuid });
      try {
        const data = await ceApiService.sendActivationCode(this.cardUuid);
        this.showStep1 = !this.showStep1;
        this.showStep2 = !this.showStep2;
        loggerService.info('Activation code sent successfully', { email: this.userEmail });
      } catch (error) {
        loggerService.error('Failed to send activation code', error);
      }
    },
    async confirmCodeToUnlockCard() {
      loggerService.info('User confirming activation code', { cardId: this.cardId });
      try {
        this.showStep2 = !this.showStep2;
        this.showStep3 = !this.showStep3;
        const data = await weavrApiService.confirmCodeToUnlockCard(
          this.cardId,
          this.verificationCode
        );
        loggerService.info('Card activation confirmed successfully', { cardId: this.cardId });
      } catch (error) {
        loggerService.error('Failed to confirm activation code', error);
      }
    },
    async unBlockCard() {
      loggerService.info('User unblocking card', { cardId: this.cardId });
      try {
        const data = await weavrApiService.unBlockCard(this.cardId);
        this.showModalToUnBlock = false;
        loggerService.info('Card unblocked successfully', { cardId: this.cardId });
        location.reload();
      } catch (error) {
        loggerService.error('Failed to unblock card', error);
      }
    },
    async blockCard() {
      loggerService.info('User blocking card', { cardId: this.cardId });
      try {
        const data = await weavrApiService.blockCard(this.cardId);
        this.showModalToUnBlock = false;
        loggerService.info('Card blocked successfully', { cardId: this.cardId });
        location.reload();
      } catch (error) {
        loggerService.error('Failed to block card', error);
      }
    },
  },
  components: { Header },
};
</script>

<style scoped lang="scss">
.dashbord {
  max-width: 1000px;
  margin: 0 auto;
  .card-details {
    width: 309px;
    position: relative;
    @media only screen and (max-width: 600px) {
      margin: auto;
    }
    .card {
      width: 309px;
      height: 200px;
      perspective: 1000px;
      margin: auto;
      .card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        transition: transform 600ms ease;
        transform-style: preserve-3d;
        box-shadow: 0 0 25px 2px rgba(0, 0, 0, 0.2);
        .card-front,
        .card-back {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          overflow: hidden;
          backface-visibility: hidden;
          background: linear-gradient(321.03deg, #01adef 0%, #0860bf 91.45%);
        }

        .card-front {
          overflow: hidden;
          position: relative;
          transition: transform 300ms ease-in-out;
          .master-card {
            width: 57px;
            position: absolute;
            bottom: 10px;
            right: 22px;
            z-index: 2;
          }

          .card-img {
            position: absolute;
            width: 100%;
            left: 0;
          }
          .card-holder {
            position: absolute;
            left: 35px;
            bottom: 10px;
            color: white;
            font-size: 14px;
            letter-spacing: 0.2em;
            filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.5));
          }
          .card-number {
            position: absolute;
            left: 35px;
            bottom: 115px;
            color: white;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.2em;
            filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
          }
          .card-valid {
            position: absolute;
            left: 35px;
            bottom: 30px;
            color: white;
            font-size: 14px;
            letter-spacing: 0.2em;
            filter: (1px 1px 1px rgba(0, 0, 0, 0.3)) drop-shadow;
          }
          .unlock-layer {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: white;
            opacity: 0.5;
            display: none;
            z-index: 2;
          }
          .unlock-card {
            width: 117px;
            height: 37px;
            display: none;
            background-image: url("@/assets/img/pictos/unlock.png");
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -58px;
            margin-top: -16px;
            z-index: 3;
          }
        }
        &:hover {
          .unlock-layer {
            display: block;
          }
          .unlock-card {
            display: block;
          }
        }
        .card-back {
          transform: rotateY(180deg);
          .card-seccode {
            position: absolute;
            top: 125px;
            left: 245px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 17px;
            color: #021318;
            background-color: #eeecec;
            text-align: center;
            font-size: 11px;
          }
          .pin {
            position: absolute;
            top: 150px;
            left: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 17px;
            color: #021318;
            background-color: #eeecec;
            text-align: center;
            font-size: 11px;
            margin-left: -50px;
          }
        }
      }
    }
    .menu {
      .menu-link {
        position: absolute;
        top: 175px;
        right: -10px;
        width: 36px;
        height: 36px;
        background: url(../../assets/img/menu.png) no-repeat 0 0;
        cursor: pointer;
      }
      ul {
        list-style: none;
        margin: 10px 0 0 0;
        padding: 0;
        position: relative;
        z-index: 2;
        li {
          text-align: left;
          position: relative;
          z-index: 1;
          margin-bottom: 10px;

          display: flex;
          flex-direction: column;
          align-items: flex-start;
          a {
            display: flex;
            align-items: center;
            gap: 10px;
            align-self: stretch;

            color: black;
            text-decoration: none;
            font-size: 13px;
            cursor: pointer;
            &:hover {
              span {
                text-decoration: underline;
              }
            }
            .menu-link-text {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            }
            .menu-link-text-description {
              font-size: 11px;
              color: gray;
              z-index: 1;
            }
          }
          .cardInfoLink {
            margin-left: 35px;
            font-size: 11px;
            color: gray;
            line-height: 11px;
            z-index: 1;
          }
        }
      }
    }
  }
}
</style>
  