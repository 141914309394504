const CE_TOKEN_KEY = "ce_token";
const CE_TOKEN_VALIDITY_KEY = "ce_token_validity";

const getTokenValidity = () => {
  return window.localStorage.getItem(CE_TOKEN_VALIDITY_KEY);
};

export const getToken = () => {
  return window.localStorage.getItem(CE_TOKEN_KEY);
};

export const saveToken = (token, ttl) => {
  window.localStorage.setItem(CE_TOKEN_KEY, token);

  let validity = Date.now()/1000 + ttl/2;
  window.localStorage.setItem(CE_TOKEN_VALIDITY_KEY, validity);
};

export const destroyToken = () => {
  window.localStorage.removeItem(CE_TOKEN_KEY);
  window.localStorage.removeItem(CE_TOKEN_VALIDITY_KEY);
};

export const authenticated = () => {
  if (!getToken() || !getTokenValidity()) {
    return false;
  }

  return Date.now()/1000 < getTokenValidity();
}

export const logout = () => {
  destroyToken();
}

const ceAuthenticationStorage = {
  getToken,
  saveToken,
  destroyToken,
  authenticated,
  logout
};

export default ceAuthenticationStorage;