<template>
  <div>
    <section class="ftco-section">
      <div class="container">
        <div class="row ">
          <div class="col-md-6 col-lg-5">
            <div class="login-wrap p-4 p-md-5">
              <br /><br />
              <Logo />
              <br /><br />
              <!-- Form login-->
              <form onSubmit="onSubmit(); return false;">
                <!-- Email-->
                <div class="form-group">
                  <label for="email">Email* : </label>
                  <input
                    id="email"
                    v-model="email"
                    type="email"
                    placeholder="Email"
                  /><br />
                </div>
                <!-- End Email -->
                <!-- Password-->
                <div class="form-group">
                  <label for="password">Mot de passe : </label>
                  <input
                    id="password"
                    v-model="password.value"
                    :type="showPassword ? 'text' : 'password'" 
                    placeholder="Mot de passe"
                    maxlength="20"
                  />
                  <a href="#" @click="toggleShowPassword " class="showHidePassword" :class="{ 'visible': showPassword }">
                    <img v-if="!showPassword" src="../assets/img/ico/visibility.png">
                    <img v-if="showPassword" src="../assets/img/ico/visibility_off.png">
                  </a>
                  <div  class="error-message">
                    {{ errorMessage }}
                  </div>
                  <div style="text-align: left; padding-top: 10px ">
                    <a href="#" class="forgotPassword" @click="openModal">Mot de passe oublié ?</a>
                  </div>
                </div>
                <!-- End Password -->
                <div class="form-group-btn">
                  <button
                    @click.prevent="login"
                    id="btn"
                    class="btn-login btn btn-primary rounded submit p-3 px-5"
                  >
                    Me connecter
                  </button>
                </div>
              </form>
              <!-- End Form login-->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <!-- Modal password forgotten-->
  <div class="modal" v-if="isModalOpen">
    <div class="modal-content">
      <span class="close" @click="closeModal">
        <img src="../assets/img/ico/cross.png" />
      </span>
      <h3>Récuparation du compte</h3>
      <div class="form-group" v-if="!mailSentConfirmation">
        <input type="email" v-model="emailToSet" placeholder="Entrez votre email" />
        <div v-if="showError" class="error-message">{{ errorMessageModal }}</div><br>
        <div class="form-group-btn">
          <button @click="resetPassword" class="btn-modal">Recevoir un email</button>
        </div>
      </div>
      <div class="mailSentConfirmation" v-if="mailSentConfirmation">
        <p>Si votre compte existe, un email vient de vous être envoyé. </p>
        <p>Rendez-vous dans votre boîte mail !</p>
      </div>
    </div>
  </div>
  <!-- End modal password forgotten -->
</template>

<script>
  import Logo from "@/components/Logo.vue";
  import weavrAuthenticationStorage from "@/_services/Storage/weavrAuthenticationStorage";
  import ceAuthenticationStorage from "@/_services/Storage/ceAuthenticationStorage";
  import { weavrApiService } from "../_services/weavrApiService";

  export default {
    methods: {
      openModal() {
        this.isModalOpen = true;
      },

      closeModal() {
        this.isModalOpen = false;
        this.emailToSet = '';
        this.showError = false;
      },

      toggleShowPassword() {
        this.showPassword = !this.showPassword;
      },

      validateEmail(emailToSet) {
        if ('' === this.emailToSet.trim()) {
          return false;
        }

        const re = /\S+@\S+\.\S+/;
        return re.test(emailToSet);
      },

      async resetPassword() {
        if (!this.validateEmail(this.emailToSet)) {
          this.showError = true;
          this.errorMessageModal = 'Veuillez saisir un email valid';

          return false;
        }

        try {
          await weavrApiService.initNewPassword(this.emailToSet);
          this.mailSentConfirmation = true;
        } catch (error) {
          if (error.response && 200 !== error.response.status) {
            this.errorMessageModal = "Erreur lors de réinitialisation de votre mot de passe";
          } else {
            this.errorMessageModal = "Erreur lors de réinitialisation de votre mot de passe";
          }
        }
      },

      async login() {
        await weavrApiService.loginWithPassword(this.email, this.password.value).then((userDetails) => {
          weavrAuthenticationStorage.saveAuthentication(userDetails.token, userDetails.credentials.id)
          this.checkUserStatus();
        }).catch((error) => {
          if (error.response && 200 !== error.response.status) {
            weavrAuthenticationStorage.logout();
            ceAuthenticationStorage.logout();
            this.errorMessage = "La combinaison email et mot de passe est incorrecte.";
          } else {
            this.errorMessage = "Erreur lors de l'authentification";
          }
        });
      },

      async checkUserStatus() {
        try {
          await weavrApiService.getUserStatus().then((authentication) => {
            if (authentication.factors) {
              let userStatus = authentication.factors[0].status;

              if ("ACTIVE" === userStatus) {
                this.$router.push("/admin/stepUp");
              } else if ("PENDING_VERIFICATION" === userStatus || "INACTIVE" === userStatus) {
                this.$router.push("/admin/stepUpRegister");
              }
            } else {
              this.$router.push("/admin/stepUpRegister");
            }
          });
        } catch (error) {
          weavrAuthenticationStorage.logout();
          ceAuthenticationStorage.logout();
          this.errorMessage = "Erreurs lors de vérification de votre compte";
        }
      },
    },

    data() {
      return {
        email: "",
        password: {
          value: "",
        },
        errorMessage: "",
        errorMessageModal: "",
        isModalOpen: false,
        mailSentConfirmation: false,
        showError :false,
        emailToSet : "",
        showPassword: false,
      };
    },

    components: { Logo },
  };
</script>

<style scoped lang="scss">
  .logo {
    position: static;
  }

  .login-wrap {
    background: #fff;
    border-radius: 20px;
    padding: 20px;
    border: 1px solid #bababa;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 350px;
    width: 100%;
    margin-left: -200px;
    margin: -200px;
  }

  .btn-login {
    cursor: pointer;
  }

  @media only screen and (max-width: 600px) {
    .login-wrap {
      padding: 20px;
      border: none;
      margin: 0 auto;
      position: static;
      max-width: 350px;
      width: 100%;
    }
  }
</style>