<template>
  <div class="user-menu">
    <div
      class="Unsername-link"
      @click="toggleUserMenu"
      :class="{ active: isUserMenuVisible }"
    >
      {{ nameOnCard }}
    </div>
    <div v-if="isUserMenuVisible" class="user-menu-links" @focusout="closeUserMenu">
      <a id="" class="user-menu-link" to="/admin/Dashboard" @click="goToDashboard">
        <img class="user-menu-link-icon" src="../../src/assets/img/ico/user.svg" alt="Mon compte" />
        <span>Mon compte</span>
      </a>
      <a class="user-menu-link" to="/" @click="logout">
        <img class="user-menu-link-icon" src="../../src/assets/img/ico/logout.svg" alt="Se déconnecter" />
        <span>Se déconnecter</span>
      </a>
    </div>
  </div>
</template>

<script>
  import { weavrApiService } from "../_services/weavrApiService";
  import ceAuthenticationStorage from "@/_services/Storage/ceAuthenticationStorage";
  import weavrAuthenticationStorage from "@/_services/Storage/weavrAuthenticationStorage";
  import router from "@/router";

  export default {
    data() {
      return {
        isUserMenuVisible: false,
        nameOnCard: "",
      };
    },

    mounted() {
      this.getUserDetails();
    },

    methods: {
      toggleUserMenu() {
        this.isUserMenuVisible = !this.isUserMenuVisible;
      },
      closeUserMenu() {
        this.isUserMenuVisible = false;
      },
      logout() {
        ceAuthenticationStorage.logout();
        weavrAuthenticationStorage.logout();

        this.$router.push("/");
      },
      goToDashboard() {
        router.push("/admin/dashboard");
      },
      async getUserDetails() {
        weavrApiService.getUserDetails()
          .then(async (response) => {
            this.nameOnCard = response.surname + ' ' + response.name;
          })
          .catch((error) => {
            this.logout();
          });
      },
    },
  };
</script>

<style scoped lang="scss">
  .user-menu-links {
    position: absolute;
    z-index: 2;
    right: 0px;
    top: 20px;

    display: flex;
    min-width: 200px;
    padding: 8px 8px 12px 8px;
    flex-direction: column;
    align-items: flex-start;

    border-radius: 20px;
    border: 1px solid rgba(11, 28, 72, 0.10);
    background-color: #fafafa;
    box-shadow: 0px 16px 20px -8px rgba(17, 12, 34, 0.10);

    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .user-menu-link {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    &:hover {
      background: rgba(39, 92, 241, 0.02);
    }

    cursor: pointer;
  }

  .user-menu-link-icon {
    width: 15px;
  }

  .user-menu {
    text-align: right;
    position: absolute;
    top: 20px;
    right: 0px;
    max-width: 200px;

    .Unsername-link {
      padding-right: 25px;
      cursor: pointer;
      &:after {
        content: "▼";
        position: absolute;
        right: 0.5em;
        top: 7px;
        transform: translateY(-50%);
      }
      &.active {
        &:after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }
</style>
