<template>
  <div v-bind:class="`${props.campaign.expired ? 'bloc-campaign bloc-campaign-expired' : 'bloc-campaign bloc-campaign-active'}`">
    <div class="bloc-campaign-titles">
      <div v-if="props.campaign.name.length < 20 " class="bloc-campaign-name">
        {{ $props.campaign.name }}
      </div>
      <div  v-else class="bloc-campaign-name tooltip">
        {{ $props.campaign.name.substr(0, 18) }}...
        <span class="tooltiptext">{{ $props.campaign.name }}</span>
      </div>

      <div class="bloc-campaign-event">
        <div class="campaign-event-name">
          {{ $props.campaign.socialEvent.name }}
        </div>
<!--        <div class="campaign-event-icon">
          <SocialEventIcon :event="props.campaign.socialEvent" />
        </div>-->
      </div>
    </div>
    <div class="bloc-campaign-validity">
      Validité du {{ props.campaign.startDate }} au {{ props.campaign.endDate }}
    </div>
    <div v-if="null !== props.campaign.amount" class="bloc-campaign-amount">
      <div class="flex justify-between mb-1 bloc-campaign-amount-numbers">
        <span class="campaign-amount" :class="{ 'campaign-amount-zero': 0 == props.campaign.amount }">{{ props.campaign.amount.toLocaleString('fr-FR', { minimumFractionDigits: 2 }) }}€</span>
        <span class="campaign-amount-total">{{ props.campaign.totalAmount.toLocaleString('fr-FR', { minimumFractionDigits: 2 }) }}€</span>
      </div>
      <div class="campaign-amount-progress w-full bg-gray-200 rounded-full h-2.5">
        <div class="campaign-amount-progress bg-blue-600 h-2.5 rounded-full" :style="{width: (props.campaign.amount/props.campaign.totalAmount*100) + '%'}"></div>
      </div>
    </div>
    <div v-else class="bloc-campaign-status-expired">
      Campagne expirée
    </div>
  </div>
</template>

<script setup>
  import SocialEventIcon from "@/components/Campaign/SocialEventIcon.vue";

  const props = defineProps({
    'campaign': {
      type: Object,
      default: () => ({
        name: null,
        socialEvent: null,
        startDate: null,
        endDate: null,
        amount: null,
        totalAmount: null,
        expired: null
      })
    }
  })
</script>

<style scoped lang="scss">
  .tooltip {
    position: relative;
    display: inline-block;
  }

/* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 10px;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }

  /* Tooltip arrow */
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .bloc-campaign {
    display: flex;
    padding: 24px 32px;
    flex-direction: column;
    align-items: center;
    flex: 1 0 0;
    border-radius: 20px;
  }

  .bloc-campaign-active {
    border: 1px solid rgba(39, 92, 241, 0.10);
    background: #FFF;
  }

  .bloc-campaign-expired {
    border: 1px solid rgba(11, 28, 72, 0.06);
    background: linear-gradient(0deg, rgba(11, 28, 72, 0.02) 0%, rgba(11, 28, 72, 0.02) 100%), #FFF;
  }

  .bloc-campaign-titles {
    width: 100%;

    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  .bloc-campaign-name {
    text-align: left;
    color: #0B1C48;
    font-size: 16px;
    font-weight: 700;
  }

  .bloc-campaign-event {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
  }

  .campaign-event-name {
    height: 100%;
    color: rgba(11, 28, 72, 0.50);
    font-size: 14px;
    font-weight: 400;
  }

  .campaign-event-icon {
    padding-top: 1px;
    height: 100%;
  }

  .campaign-event-icon-image {
    width: 14px;
    height: 14px;
  }

  .bloc-campaign-validity {
    margin-top: 5px;
    width: 100%;
    color: rgba(11, 28, 72, 0.50);
    font-size: 10px;
    font-weight: 400;
    text-align: left;
  }

  .bloc-campaign-amount {
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }

  .bloc-campaign-amount-numbers {
    display: flex;
    align-items: flex-end;
    gap: 35px;
    align-self: stretch;
  }

  .campaign-amount {
    color: #275CF1;
    font-size: 16px;
    font-weight: 700;
  }

  .campaign-amount-zero {
    color: rgba(11, 28, 72, 0.50);
  }

  .campaign-amount-total {
    color: rgba(11, 28, 72, 0.50);
    text-align: right;
    font-size: 10px;
    font-weight: 400;
  }

  .campaign-amount-progress {
    height: 4px;
  }

  .bloc-campaign-status-expired {
    margin-top: 30px;
    width: 100%;
    text-align: center;
    color: rgba(11, 28, 72, 0.50);
    font-size: 14px;
    font-weight: 700;
  }
</style>
