import { getApiKey } from '@/_helper/envHelper'
import weavrAuthenticationStorage from "@/_services/Storage/weavrAuthenticationStorage";
import { loggerService } from './loggerService';
import ceAuthenticationStorage from "@/_services/Storage/ceAuthenticationStorage";
import router from "@/router";

const API_BASE_URL = process.env.VUE_APP_WEAVR_API_URL;

async function fetchWithToken(url, options = {}, login = false, logoutIfRequestFailed = false) {
    const token = weavrAuthenticationStorage.getToken();
    const apiKey = getApiKey();

    // Log API request
    loggerService.debug(`Weavr API Request: ${options.method || 'GET'} ${url}`, {
        login,
        hasToken: !!token
    });

    if (!token && !login) {
        const error = new Error('No authentication token found');
        loggerService.error('Authentication failed - No token', error);
        throw error;
    }

    try {
        const headers = {
            ...(options.headers || {}),
            'Content-Type': 'application/json',
            'api-key': apiKey,
        };

        if (!login) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        options['headers'] = headers;

        const response = await fetch(url, {
            ...options
        });

        if (!response.ok) {
            const errorText = await response.text().catch(() => '');
            const error = new Error(`Weavr API error: ${response.status} ${response.statusText}`);
            error.response = response;
            error.status = response.status;
            error.statusText = response.statusText;
            error.responseText = errorText;

            loggerService.error(`Weavr API Error: ${options.method || 'GET'} ${url}`, {
                status: response.status,
                statusText: response.statusText,
                responseText: errorText
            });

            if (logoutIfRequestFailed) {
                await logout();
            } else {
                throw error;
            }
        }

        if (response.status === 204) {
            loggerService.debug(`Weavr API Success (No Content): ${options.method || 'GET'} ${url}`);
            return response;
        }

        const data = await response.json();
        loggerService.debug(`Weavr API Success: ${options.method || 'GET'} ${url}`);
        return data;
    } catch (error) {
        if (error.response) {
            loggerService.error(`Weavr HTTP Error: ${error.message}`, error);
        } else {
            loggerService.error(`Weavr Network Error: ${error.message}`, error);
        }

        if (logoutIfRequestFailed) {
            await logout();
        } else {
            throw error;
        }
    }
}

async function logout() {
    weavrAuthenticationStorage.logout();
    ceAuthenticationStorage.logout();
    await router.push("/");
};

export const weavrApiService = {
    async getUserDetails() {
        const userId = weavrAuthenticationStorage.getUserId();
        if (!userId) {
            throw new Error('No UserID found');
        }
        const url = `${API_BASE_URL}/multi/users/${userId}`;
        return fetchWithToken(url, {}, false, true);
    },
    async confirmCodeToUnlockCard(cardId, unlockCode) {
        const url = `${API_BASE_URL}/multi/managed_cards/${cardId}/physical/activate`;
        const options = {
            method: 'POST',
            body: JSON.stringify({
                activationCode: unlockCode,
            })
        };
        return fetchWithToken(url, options, false, false);
    },
    async unBlockCard(cardId) {
        const url = `${API_BASE_URL}/multi/managed_cards/${cardId}/unblock`;
        const options = {
            method: 'POST',
        };
        return fetchWithToken(url, options, false, false);
    },
    async blockCard(cardId) {
        const url =  `${API_BASE_URL}/multi/managed_cards/${cardId}/block`;
        const options = {
            method: 'POST',
        };
        return fetchWithToken(url, options, false, false);
    },
    async cardStatement(cardNumber){
        const urlStatments = `${API_BASE_URL}/multi/managed_cards/${cardNumber}/statement`;
        return fetchWithToken(
            urlStatments,
    {
                headers: {
                    Accept: "string",
                }
            },
          false,
          true
        );
    },
    async getCardDetails(cardNumber){
        const url = `${API_BASE_URL}/multi/managed_cards/${cardNumber}`;
        return fetchWithToken(url, {}, false, true);
    },
    async getPhysicalCardPin(cardNumber){
        const url = `${API_BASE_URL}/multi/managed_cards/${cardNumber}/physical/pin`;
        return fetchWithToken(url, {}, false, true);
    },
    async verifyStepUpOTP(stepUpCode){
        const url = `${API_BASE_URL}/multi/stepup/challenges/otp/SMS/verify`;
        const options = {
            method: 'POST',
            body: JSON.stringify({verificationCode : stepUpCode})
        };
        return fetchWithToken(url, options, false, false);
    },
    async loginWithPassword(email, password){
        const url = `${API_BASE_URL}/multi/login_with_password`;
        const options = {
            method: 'POST',
            body: JSON.stringify(
              {
                  email: email,
                  password: { value: password },
              }
            ),
        };
        return fetchWithToken(url, options, true, false);
    },
    async getUserStatus(){
        const url = `${API_BASE_URL}/multi/authentication_factors`;
        return fetchWithToken(url, {}, false, true);
    },
    async setpUp(){
        const url = `${API_BASE_URL}/multi/stepup/challenges/otp/SMS`;
        const options = {
            method: 'POST',
        };
        return fetchWithToken(url, options, false, false);
    },
    async inviteConsume(userId, nonce, password) {
        const url = `${API_BASE_URL}/multi/users/${userId}/invite/consume`;
        const options = {
            method: 'POST',
            body: JSON.stringify(
              {
                  inviteCode: nonce,
                  password: { value: password },
              }
            ),
        };
        return fetchWithToken(url, options, true, false);
    },
    async enrolUserDevice(){
        const url = `${API_BASE_URL}/multi/authentication_factors/otp/SMS`;
        const options = {
            method: 'POST',
        };
        return fetchWithToken(url, options, false, true);
    },
    async enrolUserDeviceVerify(verificationCode){
        const url = `${API_BASE_URL}/multi/authentication_factors/otp/SMS/verify`;
        const options = {
            method: 'POST',
            body: JSON.stringify(
              {
                  verificationCode: verificationCode
              }
            ),
        };
        return fetchWithToken(url, options, false, false);
    },

    async updateUserPhoneNumber(countryCode, phoneNum){
        const userId = weavrAuthenticationStorage.getUserId();
        const url = `${API_BASE_URL}/multi/users/${userId}`;
        const options = {
            method: 'PATCH',
            body: JSON.stringify(
              {
                  mobile: {
                      countryCode: countryCode,
                      number: phoneNum,
                  }
              }
            ),
        };
        return fetchWithToken(url, options, false, true);
    },

    async initNewPassword(email){
        const url = `${API_BASE_URL}/multi/passwords/lost_password/start`;
        const options = {
            method: 'POST',
            body: JSON.stringify(
              {
                  email: email,
              }
            ),
        };
        return fetchWithToken(url, options, true, false);
    },

    async setNewPassword(email, nonce, password) {
        const url = `${API_BASE_URL}/multi/passwords/lost_password/resume`;
        const options = {
            method: 'POST',
            body: JSON.stringify(
              {
                  nonce: nonce,
                  email: email,
                  newPassword: { value: password },
              }
            ),
        };
        return fetchWithToken(url, options, true, false);
    },


};
