<template>
  <div id="accordion-campaigns" class="campaign-list">
    <h2>
      <button :onClick="toggleList" type="button" class="btn-toggle-campaigns-list flex items-center justify-between w-full">
        <span class="campaigns-list-title">Détail par campagne</span>
        <svg :class="{'rotate-180': campaignsListOpen}" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0001 16.6517L5.11621 9.76777L6.88398 8L12.0001 13.1161L17.1162 8L18.884 9.76777L12.0001 16.6517Z" fill="#0B1C48" fill-opacity="0.5"/>
        </svg>
      </button>
    </h2>
    <div :class="{hidden: !campaignsListOpen}">
      <div class="py-5">
        <!-- Campaigns-->
        <div class="grid md:grid-cols-3 sm:grid-cols-2 s gap-4">
          <Campaign v-for="(campaign, index) in campaignsPage" :campaign="campaign" />
        </div>
        <!-- End Campaigns-->
        <!-- Pagination-->
        <div v-if="1 < nbrPages" class="campaigns-pagination-section">
          <div class="campaigns-pagination-text">
            <span>{{ startCampaign }}</span> &nbsp;à&nbsp; <span>{{ endCampaign }}</span> &nbsp; / &nbsp; <span>{{ nbrCampaigns }}</span> &nbsp;campagnes
          </div>
          <nav class="campaigns-pagination-nav">
            <button
              class="campaigns-pagination-button campaigns-pagination-button-move"
              :onClick="() => handleChangePage(currentPage - 1)"
            >
            <span>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.32548 9.00007L11.1626 5.16298L9.83674 3.83716L4.67383 9.00007L9.83674 14.163L11.1626 12.8372L7.32548 9.00007Z" fill="#0B1C48"></path>
              </svg>
            </span>
            </button>
            <button
              v-for="page in nbrPages"
              class="campaigns-pagination-button campaigns-pagination-button-number"
              :class="{ 'campaigns-pagination-button-number-active': currentPage === page }"
              :onClick="() => handleChangePage(page)"
            >
              {{ page }}
            </button>
            <button
              class="campaigns-pagination-button campaigns-pagination-button-move"
              :onClick="() => handleChangePage(currentPage + 1)"
            >
            <span>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.674 9.00007L6.83691 5.16298L8.16274 3.83716L13.3257 9.00007L8.16274 14.163L6.83691 12.8372L10.674 9.00007Z" fill="#0B1C48"></path>
              </svg>
            </span>
            </button>
          </nav>
        </div>
        <!-- End Pagination-->
      </div>
    </div>
  </div>
</template>

<script>
  import {getNbrCampaignsPerPagePagination} from "@/_helper/envHelper";
  import {ceApiService} from "@/_services/ceApiService";
  import Campaign from "@/components/Campaign/Campaign.vue";

  export default {
    props: {
      'campaigns': {
        type: Array,
      }
    },
    data() {
      return {
        campaignsListOpen: false,
        campaigns: [],
        nbrCampaigns: null,
        campaignsPage: [],
        currentPage: 1,
        startCampaign: null,
        endCampaign: null,
        nbrPages: 1,
      }
    },
    async mounted() {
        const nbrCampaignsPerPage = getNbrCampaignsPerPagePagination();
        const response = await ceApiService.getCampaigns();
        this.campaigns = response.data["hydra:member"];
        this.nbrCampaigns = this.campaigns.length;
        this.nbrPages = this.nbrCampaigns <= nbrCampaignsPerPage ? 1 : (Math.floor(this.nbrCampaigns/nbrCampaignsPerPage) + 1);
        this.campaignsPage = this.campaigns.slice(0, nbrCampaignsPerPage);
        this.startCampaign = 1;
        this.endCampaign = this.nbrCampaigns > nbrCampaignsPerPage ? nbrCampaignsPerPage : this.nbrCampaigns;
    },
    methods: {
      toggleList() {
        this.campaignsListOpen = !this.campaignsListOpen;
      },
      handleChangePage(newPage) {
        const nbrCampaignsPerPage = getNbrCampaignsPerPagePagination();

        if (newPage === this.currentPage || 1 > newPage || this.nbrPages < newPage) {
          return;
        }

        this.currentPage = newPage;

        let startIndex = (this.currentPage-1) * nbrCampaignsPerPage;
        let endIndex = startIndex + nbrCampaignsPerPage;

        this.campaignsPage = this.campaigns.slice(startIndex, endIndex);
        this.startCampaign = startIndex + 1;
        this.endCampaign = this.currentPage === this.nbrPages ? this.campaigns.length : endIndex;
      }
    },
    components: { Campaign },
  }
</script>

<style scoped lang="scss">
  @import "@/assets/style/flowbite.css";

  .campaign-list {
    font-family: Figtree;
    font-style: normal;
    line-height: normal;
  }

  .campaigns-list-title {
    color: #275CF1;
    font-size: 20px;
    font-weight: 700;
  }

  .btn-toggle-campaigns-list {
    cursor: pointer;
  }

  .campaigns-pagination-section {
    margin-top: 20px;
  }

  .campaigns-pagination-text {
    flex: 1 0 0;
    color: #0B1C48;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
  }

  .campaigns-pagination-nav {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    align-self: stretch;
    border-radius: 0 0 25px 25px;
  }

  .campaigns-pagination-button {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    cursor: pointer;
  }

  .campaigns-pagination-button-move {
    padding: 11px;
    margin-left: 20px;
    margin-right: 20px;
    border: 1px solid rgba(11, 28, 72, 0.10);
    background-color: #FFF;
  }

  .campaigns-pagination-button-number {
    padding: 8px 12px;
    background: var(--color-transparent, rgba(255, 255, 255, 0.00));
  }

  .campaigns-pagination-button-number-active {
    color: #fff;
    background-color: #275cf1;
  }
</style>