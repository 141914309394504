<template>
  <div class="card-section">
    <!-- Image of card -->
    <div class="card-image" :class="{ hover: isHovered }">
      <div class="btn-show-code-card" :class="{ hideCode: isHovered }"  @click="toggleCodeClass">
        <img v-if="!isHovered" src="@/assets/img/ico/eye_off.svg" style="width: 18px; height: 13px;" />
        <img v-if="isHovered" src="@/assets/img/ico/eye.png" style="width: 18px; height: 13px;" />
      </div>
      <div class="card-inner">
        <CardImageFront :name-on-card="nameOnCard" :expiry-mmyy="expiryMmyy" />
        <CardImageBack :card-type="cardType" />
      </div>
    </div>
    <!-- End Image of card -->
    <!-- Card info -->
    <div v-if="cardInfoLoaded"  class="cart-info">
      <div class="cart-actions grid md:grid-cols-2 sm:grid-cols-1 s gap-4">
        <div class="cart-actions-view-sides" :class="{ hideCode: isHovered }" type="button" @click="toggleCodeClass">
          <img v-if="!isHovered" src="@/assets/img/ico/visibility.png" style="width: 12px; height: 10px;" />
          <img v-if="isHovered" src="@/assets/img/ico/visibility_off.png" style="width: 12px; height: 12px;" />
          <span>
            Voir le <span v-if="!isHovered">verso</span> <span v-if="isHovered">recto</span> de la carte
          </span>
        </div>
        <div v-if="cardType === 'PHYSICAL'"  class="cart-actions-view-pin"  type="button" @click="triggerHover">
          <img src="@/assets/img/ico/lock.png">
          <span class="cart-actions-view-pin-text">Voir le code PIN</span>
        </div>
      </div>
      <CardBalance :balance="balance" :for-mobile="false"/>
    </div>
    <!-- End Card info -->
  </div>
  <CardBalance v-if="cardInfoLoaded" :balance="balance" :for-mobile="true"/>
</template>
<script>
  import { weavrApiService } from "@/_services/weavrApiService";
  import { ceApiService } from "@/_services/ceApiService";
  import weavrAuthenticationStorage from "@/_services/Storage/weavrAuthenticationStorage";
  import ceAuthenticationStorage from "@/_services/Storage/ceAuthenticationStorage";
  import Header from "@/components/Header.vue";
  import CardImageFront from "@/components/Card/CardImage/CardImageFront.vue";
  import CardImageBack from "@/components/Card/CardImage/CardImageBack.vue";
  import CardBalance from "@/components/Card/CardBalance.vue";

export default {
  data() {
    return {
      nameOnCard: "",
      expiryMmyy: "",
      cardType: "",
      balance: "",
      isUserMenuVisible: false,
      isHovered: false,
      cardInfoLoaded: false,
    };
  },
  async mounted() {
    const mountCardInformation = (key, information, elementId, style = null)  => {
      const weavrToken = weavrAuthenticationStorage.getToken();
      let span = style ? window.OpcUxSecureClient.span(key, information, style)
        : window.OpcUxSecureClient.span(key, information);

      window.OpcUxSecureClient.associate(
        `Bearer ${weavrToken}`,
        function () {
          span.mount(document.getElementById(elementId));
        },
        function (e) {
          console.error("associate failed " + e);
        }
      );
    }

    ceApiService.getCurrentUserCardDetails()
      .then(async (response) => {
        let card = response.data["hydra:member"][0];
        let cardNumber = card.identifiant;

        try {
          const totalBalanceActiveCampaigns = await ceApiService.getTotalBalanceActiveCampaigns();
          const cardDetails = await weavrApiService.getCardDetails(cardNumber);
          const cardPin = await weavrApiService.getPhysicalCardPin(cardNumber);

          this.nameOnCard = cardDetails.nameOnCard;
          this.expiryMmyy = cardDetails.expiryMmyy.replace(
            /(\d{2})(\d{2})/,
            "$1/$2"
          );
          this.cardType = cardDetails.type;
          this.balance = totalBalanceActiveCampaigns.data.totalBalance;

          this.cardInfoLoaded = true;

          // Mount card number
          mountCardInformation(
            "cardNumber",
            cardDetails.cardNumber.value,
            "cardNumber",
            { style: { fontSize: "16px", color: "#FFFFFF", height: "20px", }, }
          );

          // Mount pin code
          mountCardInformation(
            "cardPin",
            cardPin.pin.value,
            "pin",
            { style: { fontSize: "12px", color: "#021318", height: "20px", }, }
          );

          // Mount CVV code
          mountCardInformation("cvv", cardDetails.cvv.value, "cvv");
        } catch (error) {
          console.error("Error:", error);
        }
      })
      .catch((error) => {
        ceAuthenticationStorage.logout();
        weavrAuthenticationStorage.logout();
        this.$router.push("/");
      });
  },
  methods: {
    triggerHover() {
      this.isHovered = true;

      setTimeout(() => {
        this.isHovered = false;
      }, 5000);
    },
    toggleCodeClass() {
      this.isHovered = !this.isHovered;
    },
  },
  components: {CardBalance, CardImageBack, CardImageFront, Header },
};
</script>

<style scoped lang="scss">
  .card-section {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
    gap: 46px;
    align-self: stretch;
    flex-wrap: wrap;
  }

  .card-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
  }

  .cart-actions-view-sides {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    background: rgba(255, 255, 255, 0.00);
    cursor: pointer;
  }

  .cart-actions-view-pin {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid var(--ce-color-blue-ce-marque-blanche-200, rgba(39, 92, 241, 0.10));
    background: var(--ce-color-blue-ce-marque-blanche-50, rgba(39, 92, 241, 0.04));
    cursor: pointer;
  }

  .cart-actions-view-pin-text {
    color: #275cf1;
    font-family: "Figtree", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  .card-image {
    width: 309px;
    height: 202px;
    perspective: 1000px;
    margin-top: 30px;

    .btn-show-code-card {
      position: absolute;
      top : -10px;
      right: -10px;
      background:  #11A783;
      border-radius: 15px;
      width: 25px;
      height: 25px;
      line-height: 25px;
      z-index: 3;
      padding: 3px;
      cursor: pointer;
      img{
        width: 17px;
        position: relative;
        top: 4px;
      }
    }

    &.hover .card-inner {
      transform: rotateY(180deg);
    }

    .card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      transition: transform 600ms ease;
      transform-style: preserve-3d;
      box-shadow: 0 0 25px 2px rgba(0, 0, 0, 0.2);

      .card-side {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
        backface-visibility: hidden;
        background: linear-gradient(321.03deg, #01adef 0%, #0860bf 91.45%);
        background-size: 309px 223px;
      }
    }
  }
</style>
