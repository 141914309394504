import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'

// Import logger after core app dependencies
import { loggerService } from './_services/loggerService'

// Initialize logging
try {
  loggerService.info('Application starting')
} catch (error) {
  console.error('Failed to initialize logger:', error)
}

const pinia = createPinia()
const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://8b5fd380544b10f0c031940cb1033f87@sentry.club-employes.com/4"
});

// Global error handler
app.config.errorHandler = (err, instance, info) => {
  loggerService.error('Global error caught', err)
  console.error(err)
}

// Mount application
app.use(store).use(router).use(pinia).mount('#app')