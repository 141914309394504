<template>
  <div class="card-balance" v-bind:class="`${props.forMobile ? 'card-balance-mobile' : 'card-balance-web'}`">
    <div class="balance-title">Solde restant</div>
    <div class="balance-value">
      <b>{{ balance.toLocaleString('fr-FR', { minimumFractionDigits: 2 }) }}€</b>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    'balance': {
      type: String,
    },
    'forMobile': {
      type: Boolean
    }
  })
</script>

<style scoped lang="scss">
  .card-balance {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    align-self: stretch;
    margin-top: 15px;
    padding: 16px 32px;

    border-radius: 20px;
    border: 1px solid var(--ce-color-blue-ce-marque-blanche-100, rgba(39, 92, 241, 0.06));
    background: var(--ce-color-light-100, #FFF);
    box-shadow: 0 2px 2px 0 rgba(147, 197, 253, 0.08);
  }

  .balance-title {
    color: var(--ce-color-blue-ce-marque-blanche-950, #0B1C48);
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }

  .balance-value {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    color: var(--CE-Color-Blue-CE-500, #275CF1);
    text-align: center;
    font-size: 40px;
    font-weight: 700;
  }

  /*Web screen*/
  @media only screen and (min-width: 630px) {
    .card-balance-mobile {
      display: none;
    }

    .card-balance-web {
      display-model: block;
    }
  }

  /*Mobile screen*/
  @media only screen and (max-width: 630px) {
    .card-balance-mobile {
      width: 100%;
      display: block;
    }

    .card-balance-web {
      display: none;
    }
  }

</style>