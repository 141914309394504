<template>
  <br /><br /><br />
  <div class="stepUp">
    <h3>Double authentification</h3>
    <form @submit.prevent="verifyCode">
      <div class="form-group">
        <p><img src="@/assets/stepUp.png" /></p>
        <p>
          Un code vient de vous être envoyé par SMS. Entrer ce code à
          <b>6 chiffres</b>
          ci-dessous pour valider votre connexion.
        </p>
        <br />
        <input
          v-model="verificationCode"
          type="text"
          placeholder="Entrer le code de connexion"
        />
        <div v-if="errorMessage" class="error-message">
          {{ errorMessage }}
        </div>
        <br />
      </div>
      <div class="form-group-btn">
        <button type="submit" class="btn btn-step-up">Valider mon code</button>
      </div>
    </form>
  </div>
</template>

<script>
  import { ref } from "vue";
  import { useRouter } from "vue-router";
  import { weavrApiService } from "@/_services/weavrApiService";

  export default {
    setup() {
      const verificationCode = ref("");
      const errorMessage = ref("");
      const router = useRouter();

      const verifyCode = () => {
          weavrApiService.verifyStepUpOTP(verificationCode.value)
            .then(async (response) => {
              await router.push("/admin/dashboard");
            })
            .catch((error) => {
              if (error.response && 204 !== error.response.status) {
                errorMessage.value = "Le code saisi est incorrect";
              } else {
                errorMessage.value = "Erreur lors de vérification du code";
              }
            });
      };

      return {
        verificationCode,
        verifyCode,
        errorMessage,
      };
    },

    async beforeMount() {
      try {
        await weavrApiService.setpUp();
      } catch (error) {
        this.errorMessage = "Erreurs lors de l'authentification";
      }
    },
  };
</script>

<style scoped>
  .stepUp {
    max-width: 350px;
    margin: auto;
    border: 1px solid #bababa;
    border-radius: 20px;
    padding: 20px;
  }

  .btn-step-up {
    cursor: pointer;
  }
</style>