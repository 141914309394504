<template>
  <div class="logo">
    <picture>
      <source
        media="(max-width: 600px)"
        srcset="@/assets/img/logo_mobile.png"
      />
      <img src="@/assets/img/logo.svg" />
    </picture>
  </div>
</template>
<script>
import UserAccountMenu from "@/components/UserAccountMenu.vue";

export default {
  components: { UserAccountMenu },
  methods: {},
};
</script>

<style scoped lang="scss">
.logo {
  position: absolute;

  left: 0px;
  top: 10px;
}
</style>
