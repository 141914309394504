<template>
  <div class="tabs">
    <div class="tabset">
      <!-- Tab 1 -->
      <input
        type="radio"
        name="tabset"
        id="tab1"
        aria-controls="marzen"
        checked
      />
      <span class="operation-list-title">Détails des opérations</span>
      <div class="tab-panels">
        <section id="operations-wrapper" class="tab-panel">
          <ul class="operations-list">
            <li
              v-for="(statement, index) in filteredStatements"
              :key="index"
            >
              <div class="operation-picto"></div>
              <div class="operation-description">
                <div class="operation-title">
                  {{ getStatementName(statement) }}
                </div>
                <div class="operation-details">
                      <span>
                        {{ formatDate(statement.processedTimestamp) }}
                      </span>
                  <span
                    class="status"
                    v-if="
                          statement.transactionId.type !==
                            'AUTHORISATION_DECLINE' &&
                          statement.entryState === 'COMPLETED'
                        "
                  >
                        - Traitement terminé
                      </span>
                  <span
                    class="declined"
                    v-if="
                          statement.transactionId.type ===
                          'AUTHORISATION_DECLINE'
                        "
                  >
                        - Operation déclinée
                      </span>
                </div>
              </div>
              <div class="operation-amount">
                <div
                  v-if="
                        statement.transactionId.type == 'AUTHORISATION_DECLINE'
                      "
                >
                  <del>
                    <b
                    >{{
                        (statement.transactionAmount.amount / 100).toFixed(
                          2
                        )
                      }}
                      €</b
                    >
                  </del>
                </div>
                <div v-else>
                      <span v-if="statement.transactionId.type == 'TRANSFER'"
                      >+</span
                      >
                  <b
                  >{{
                      (statement.transactionAmount.amount / 100).toFixed(2)
                    }}
                    €</b
                  >
                </div>
              </div>
            </li>
          </ul>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
  import {ceApiService} from "@/_services/ceApiService";
  import {loggerService} from "@/_services/loggerService";
  import {weavrApiService} from "@/_services/weavrApiService";

  export default {
    data() {
      return {
        statements: [],
        validTransactionTypes: [
          "TRANSFER",
          "AUTHORISATION_DECLINE",
          "SETTLEMENT",
        ],
      }
    },
    async mounted() {
      loggerService.info('Operations component mounted');

      // Get card number
      let responseCartDetail = await ceApiService.getCurrentUserCardDetails();
      let cardNumber = responseCartDetail.data["hydra:member"][0].identifiant;

      // Get statements
      const statements = await weavrApiService.cardStatement(cardNumber);
      this.statements = statements.entry;
    },
    computed: {
      filteredStatements() {
        return this.statements ? this.statements.filter((item) =>
          item.transactionId && this.validTransactionTypes.includes(item.transactionId.type)
        ) : [];
      },
    },
    methods: {
      formatDate(ts) {
        const date = new Date(ts);
        // Format the date as needed
        return `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
          .getHours()
          .toString()
          .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
      },
      getStatementName(statement) {
        if ("merchantName" in statement.additionalFields) {
          return statement.additionalFields.merchantName;
        } else if ("sourceInstrumentFriendlyName" in statement.additionalFields) {
          return statement.additionalFields.sourceInstrumentFriendlyName;
        }

        return "";
      },
    },
  }
</script>

<style scoped lang="scss">
  .operation-list-title {
    color: #275CF1;
    font-size: 20px;
    font-weight: 700;
  }

  ul.operations-list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 100vh;
    overflow-y: scroll;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid #f1f1f1;
      .operation-picto {
        width: 50px;
        height: 50px;
        background-color: #f1f1f1;
      }
      .operation-amount {
        width: 100px;
        text-align: right;
      }
      .operation-description {
        flex: 1;
        padding: 0 10px;
        text-align: left;
        .operation-title {
          text-transform: capitalize;
        }
        .operation-details {
          span {
            text-transform: lowercase;
            font-size: 12px;
          }
        }
      }
      .operation-amount {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
</style>