import router from '@/router'

export function getApiKey() {
    return isUrlGifteo() ? process.env.VUE_APP_GF_API_KEY : process.env.VUE_APP_CE_API_KEY;
}
export function getUIKey() {
    return isUrlGifteo() ? process.env.VUE_APP_GF_UI_KEY : process.env.VUE_APP_CE_UI_KEY;
}
export function getGifteoIndentityId() {
    return process.env.VUE_APP_GF_IDENTITY_ID;
}
export function getCeIndentityId() {
    return process.env.VUE_APP_CE_IDENTITY_ID;
}
export function getLogServerUrl() {
    return process.env.VUE_APP_LOG_SERVER_URL || 'http://localhost:3030/api/logs';
}
export function getLogLevel() {
    return process.env.VUE_APP_LOG_LEVEL || 'debug';
}
export function isProd() {
    return process.env.NODE_ENV === 'production';
}

function isUrlGifteo() {
    const urlPath = window.location.pathname;
    return urlPath.includes(urlPath.includes(process.env.VUE_APP_WEAVR_GF_APP_URL));
}

export function getNbrCampaignsPerPagePagination() {
    let nbrCampaignsPerPage = process.env.VUE_APP_CAMPAIGN_PAGINATION;

    if (!process.env.VUE_APP_CAMPAIGN_PAGINATION) {
        return 10;
    }

    return nbrCampaignsPerPage;
}