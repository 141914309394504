import { loggerService } from './loggerService';
import ceAuthenticationStorage from "@/_services/Storage/ceAuthenticationStorage";
import weavrAuthenticationStorage from "@/_services/Storage/weavrAuthenticationStorage";
import router from "@/router";
const CE_API_BASE_URL = process.env.VUE_APP_CE_API_URL;

async function fetchWithToken(url, options = {}, withAuthorizationHeader = true) {
    loggerService.debug(`API Request: ${options.method || 'GET'} ${url}`);

    try {
        let headers = {
            'Content-Type': 'application/json',
        };

        if (withAuthorizationHeader) {
            const ceToken = ceAuthenticationStorage.getToken();
            headers['Authorization'] = `Bearer ${ceToken}`;
        }

        const response = await fetch(url, {
            ...options,
            headers: headers
        });

        if (!response.ok) {
            const errorText = await response.text();
            const error = new Error(`HTTP error! Status: ${response.status}`);
            error.status = response.status;
            error.responseText = errorText;
            throw error;
        }

        const data = await response.json();
        loggerService.debug(`API Response: ${options.method || 'GET'} ${url} - Success`);
        return data;
    } catch (error) {
        loggerService.error(`API Error: ${options.method || 'GET'} ${url}`, error);
        throw error;
    }
}

async function beforeRequest() {
    if (ceAuthenticationStorage.authenticated()) {
        return;
    }

    if (!weavrAuthenticationStorage.loggedIn()) {
        ceAuthenticationStorage.logout();
        weavrAuthenticationStorage.logout();
        await router.push('/');
    }

    const url = `${CE_API_BASE_URL}/wv_authentication`;
    const body = {
        weavrUserId: weavrAuthenticationStorage.getUserId(),
        weavrToken: weavrAuthenticationStorage.getToken()
    }
    const options = {
        method: 'POST',
        body: JSON.stringify(body)
    }

    try {
        let response = await fetchWithToken(url, options, false);
        let token = response.data.token.token;
        let ttl = response.data.token.ttl;
        ceAuthenticationStorage.saveToken(token, ttl);
    } catch (error) {
        ceAuthenticationStorage.logout();
        weavrAuthenticationStorage.logout();
        await router.push('/');
    }
}

export const ceApiService = {
    async sendActivationCode(cardUuid) {
        await beforeRequest();

        const url = `${CE_API_BASE_URL}/ce_weavr/wv_managed_cards/${cardUuid}/sendActivationCode`;
        const options = {
            method: 'POST',body: JSON.stringify({}),
        };

        return fetchWithToken(url, options);
    },
    async getCurrentUserCardDetails() {
        await beforeRequest();

        const userId = weavrAuthenticationStorage.getUserId();
        const url = `${CE_API_BASE_URL}/ce_weavr/wv_managed_cards?user.authorisedUserId=` + userId;

        return fetchWithToken(url);
    },
    async getCampaigns() {
        await beforeRequest();

        const url = `${CE_API_BASE_URL}/ce_weavr/wv_campaign_user`;

        return fetchWithToken(url);
    },
    async getTotalBalanceActiveCampaigns() {
        await beforeRequest();

        const url = `${CE_API_BASE_URL}/ce_weavr/wv_campaign_user/total_balance`;

        return fetchWithToken(url);
    },
};