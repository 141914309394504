import { getLogLevel, getLogServerUrl } from '@/_helper/envHelper';
import * as Sentry from "@sentry/browser";

/**
 * Simple logger service that sends logs to a Node.js logging server
 * Logs are stored in files on the server
 */
class Logger {
  constructor() {
    this.level = getLogLevel();
    this.levels = {
      debug: 0,
      info: 1,
      warn: 2,
      error: 3
    };
    
    // URL to the logging server
    this.logServerUrl = getLogServerUrl();
    
    // Keep track of failed log requests
    this.failedLogs = [];
    
    // Maximum retries for failed logs
    this.maxRetries = 3;
    
    // App info to include with each log
    this.appInfo = {
      app: 'weaver-portal',
      version: process.env.VUE_APP_VERSION || '0.1.0',
      environment: process.env.NODE_ENV
    };
  }

  /**
   * Format log message with timestamp and app info for console output
   */
  formatMessage(level, message, meta = null) {
    let formattedMeta = '';
    
    // Format metadata if provided
    if (meta) {
      if (meta instanceof Error) {
        formattedMeta = `\n  Error: ${meta.message}\n  Stack: ${meta.stack || 'No stack trace'}`;
      } else if (typeof meta === 'object') {
        try {
          formattedMeta = `\n  Context: ${JSON.stringify(meta, null, 2)}`;
        } catch (e) {
          formattedMeta = '\n  Context: [Object cannot be stringified]';
        }
      } else {
        formattedMeta = `\n  Context: ${meta}`;
      }
    }
    
    return `[${level.toUpperCase()}] ${message}${formattedMeta}`;
  }

  /**
   * Check if the given log level should be logged
   */
  shouldLog(level) {
    return this.levels[level] >= this.levels[this.level];
  }
  
  /**
   * Send log to the logging server
   */
  async sendLog(level, message, meta) {
    try {
      // Format error objects for better serialization
      let metaData = meta;
      if (meta instanceof Error) {
        metaData = {
          errorMessage: meta.message,
          stack: meta.stack || 'No stack trace',
          name: meta.name
        };
      }
      
      // Prepare log data
      const logData = {
        level,
        message,
        meta: {
          ...metaData,
          ...this.appInfo,
          userAgent: navigator.userAgent,
          url: window.location.href
        }
      };
      
      // Send log to server
      const response = await fetch(this.logServerUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(logData)
      });
      
      if (!response.ok) {
        throw new Error(`Log server returned ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Failed to send log to server:', error);
      
      // Store failed log for retry
      this.failedLogs.push({
        level,
        message,
        meta,
        retries: 0
      });
    }
  }
  
  /**
   * Retry sending failed logs
   */
  retryFailedLogs() {
    if (this.failedLogs.length === 0) return;
    
    console.log(`Retrying ${this.failedLogs.length} failed logs...`);
    
    // Create a copy of the failed logs array
    const logs = [...this.failedLogs];
    this.failedLogs = [];
    
    // Try to send each failed log again
    logs.forEach(log => {
      if (log.retries < this.maxRetries) {
        log.retries++;
        this.sendLog(log.level, log.message, log.meta).catch(() => {
          // If it fails again, add it back to the failed logs
          this.failedLogs.push(log);
        });
      }
    });
  }

  /**
   * Log debug message
   */
  debug(message, meta = null) {
    Sentry.captureMessage(this.formatMessage('debug', message, meta));

    if (this.shouldLog('debug')) {
      console.debug(this.formatMessage('debug', message, meta));
      this.sendLog('debug', message, meta);
    }
  }

  /**
   * Log info message
   */
  info(message, meta = null) {
    Sentry.captureMessage(this.formatMessage('info', message, meta));

    if (this.shouldLog('info')) {
      console.info(this.formatMessage('info', message, meta));
      this.sendLog('info', message, meta);
    }
  }

  /**
   * Log warning message
   */
  warn(message, meta = null) {
    Sentry.captureMessage(this.formatMessage('warn', message, meta));

    if (this.shouldLog('warn')) {
      console.warn(this.formatMessage('warn', message, meta));
      this.sendLog('warn', message, meta);
    }
  }

  /**
   * Log error message
   */
  error(message, error = null) {
    Sentry.captureMessage(this.formatMessage('error', message, error));

    if (this.shouldLog('error')) {
      console.error(this.formatMessage('error', message, error));
      this.sendLog('error', message, error);
    }
  }
  
  /**
   * Set the minimum log level
   */
  setLevel(level) {
    if (this.levels[level] !== undefined) {
      this.level = level;
      console.info(`Log level set to: ${level}`);
    } else {
      console.error(`Invalid log level: ${level}`);
    }
  }
}

export const loggerService = new Logger();