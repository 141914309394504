<template>
  <Header />
  <div class="page-campaigns">
    <CardSection />
    <CampaignList />
  </div>
</template>
<script>
  import Header from "@/components/Header.vue";
  import CardSection from "@/components/Card/CardSection.vue";
  import CampaignList from "@/components/Campaign/CampaignList.vue";

  export default {
    components: { Header, CardSection, CampaignList },
  };
</script>

<style scoped lang="scss">
  .page-campaigns {
    font-family: Figtree;
    font-style: normal;
    line-height: normal;

    display: flex;
    justify-content: center;
    flex-direction: column;

    padding-left: 10px;
    padding-right: 10px;
    max-width: 1000px;
    margin: 0 auto;

    .dashbord-header {
      position: relative;
    }
  }
</style>
  