import router from '@/router'
import weavrAuthenticationStorage from "@/_services/Storage/weavrAuthenticationStorage";
import ceAuthenticationStorage from "@/_services/Storage/ceAuthenticationStorage";

export async function authGuard(to) {
    if ('login' === to.matched[0].name) {
        weavrAuthenticationStorage.logout();
        ceAuthenticationStorage.logout();

        return true;
    }

    if('Admin' !== to.matched[0].name) {
        return true;
    }

    // WeavrToken is required
    if (!weavrAuthenticationStorage.loggedIn()) {
        weavrAuthenticationStorage.logout();
        ceAuthenticationStorage.logout();
        await router.push('/');
    }

    return true;
}